import React from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import { one } from 'images/product/salaris'
import Helmet from 'react-helmet'
import { belastingdienst , belastingdienstIllustration } from 'images'
import Layout from '../../../layouts'
import { confetti } from 'images/product/salaris'
import { wabPaper } from 'images'

class AanvragenLoonbelastingnummerPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      email: '',
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })


    changeHandler = event => {
      this.setState({
        email: event.target.value
      });
    }

    submitFormHandler = event => {
      event.preventDefault();

      window.analytics.track("User downloaded WAB whitepaper", {
          email: this.state.email
      });
    }


  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="salaris tools animated fadeInPage">

          <Helmet>
            <title>Download gratis gids over de WAB | Employes</title>
            <meta name="description" content="Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips." />
            <meta itemprop="name" content="Download gratis gids over de WAB | Employes" />
            <meta itemprop="description" content="Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips." />
            <meta itemprop="image" content="../../static/meta-WAB.png" />
            <meta name="robots" content="noindex,follow" />

              {/* Windows tags */}
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="msapplication-TileImage" content="static/favicon/mstile-144x144.png" />

              {/* OpenGraph tags */}
              <meta property="og:url" content="" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Download gratis gids over de WAB | Employes" />
              <meta property="og:description" content="Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips." />
              <meta property="og:image" content="../../static/meta-WAB.png" />

              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="Download gratis gids over de WAB | Employes" />
              <meta name="twitter:description" content="Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips." />
              <meta name="twitter:image" content="../../static/meta-WAB.png" />
          </Helmet>

          <header className="header padding-xxl animated fadeInPage">
            <div className="container-md">
              <div className="grid yg center text-center">

                <div className="col-12 margin-l-bottom">
                  <p className="eyebrow">Whitepaper</p>
                  <h1>Wet arbeidsmarkt in balans</h1>
                  <p className="streamer center">Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips.</p>
                </div>

                <div className="col-12 no-pad relative whitepaper-img-container">

                  {/*<div className="screenshot">
                    <img src={wabPaper} alt="Illustration" ref="screenshot" />
                  </div>

                  <div className="confetti">
                    <img src={confetti} alt="The confetti with different faces" />
                  </div>
                  */}
                </div>
              </div>
            </div>
          </header>

          <section className="tools-cards padding-xxl wab-download-section">
            <div className="container-md">
              <div className="grid yg center">
                <div className="col-7">

                  <div className="tools-cards-single card">
                    <div className="title margin-s-bottom">
                      <h5 className="eyebrow turq no-margin">Wet arbeidsmarkt in balans</h5>
                      <h2>Download de whitepaper</h2>
                      <p>Voer hieronder je emailadres in en je krijgt direct onze gids met alle tips & tricks over de WAB.</p>
                    </div>

                    <div className="form">
                      <form name="wab-guide" method="POST" action="/tools/wet-arbeidsmarkt-in-balans-voltooid/" data-netlify="true" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="wab-guide" />
                        <input type="email" name="email" placeholder="E-mailadres *" className="margin-s-bottom" required />
                        <button type="submit" className="btn primary lg flex">Download whitepaper</button>
                      </form>
                    </div>


                  </div>
                </div>

              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default AanvragenLoonbelastingnummerPage
